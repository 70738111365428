@import './fonts';
@import './vars';
@import './cookiebot';

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
    font-family: $primaryFont !important;
}

a {
	color: $primary;
	text-decoration: none;
}